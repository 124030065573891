export const redemptions = [
    {
        "title": "Magoo",
        "timer": 5
    },
    {
        "title": "20 Minute Drill",
        "timer": 20
    },
    {
        "title": "Drill",
        "timer": 20
    },
    {
        "title": "Hesitate",
        "timer": 5
    },
    {
        "title": "Sideways",
        "timer": 5
    }
];